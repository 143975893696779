<template>
  <v-card flat>
    <v-card-text>
      <v-row dense>
        <v-col :cols="9">
          <v-card>
            <v-card-text>
              <v-row dense>
                <v-col>
                  <v-text-field
                      v-model="filters.DateSelection1"
                      hide-details
                      dense
                      outlined
                      label="از تاریخ"
                      class="start-date-picker"
                  />
                  <date-picker
                      :max="filters.DateSelection2"
                      auto-submit
                      v-model="filters.DateSelection1"
                      format="jYYYY/jMM/jDD"
                      custom-input=".start-date-picker"
                  />

                </v-col>
                <v-col>
                  <v-text-field
                      v-model="filters.DateSelection2"
                      class="end-date-picker"
                      hide-details
                      dense
                      outlined
                      label="تا تاریخ"
                  />
                  <date-picker
                      :min="filters.DateSelection1"
                      auto-submit
                      v-model="filters.DateSelection2"
                      format="jYYYY/jMM/jDD"
                      custom-input=".end-date-picker"
                  />
                </v-col>
                <v-col>
                  <v-select
                      clearable
                      v-model="filters.TypeOfTransaction"
                      :items="transactionType"
                      item-text="name"
                      item-value="id"
                      hide-details
                      dense
                      outlined
                      label="نوع معامله"
                  />
                </v-col>
                <v-col>
                  <v-select
                      clearable
                      v-model="filters.PropertyType"
                      :items="fileTypes"
                      item-text="name"
                      item-value="id"
                      hide-details
                      dense
                      outlined
                      label="نوع ملک"
                  />
                </v-col>
                <v-col>
                  <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          outlined
                          text
                          large
                          v-bind="attrs"
                          v-on="on"
                      >
                        قیمت کل / رهن
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-text-field
                            v-model="filters.MinmumTotalprice"
                            dense
                            outlined
                            label="از قیمت"
                        />

                        <v-text-field
                            v-model="filters.MaximumTotalprice"
                            dense
                            outlined
                            label="تا قیمت"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col>
                  <v-menu
                      bottom
                      origin="center center"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          outlined
                          text
                          large
                          v-bind="attrs"
                          v-on="on"
                      >
                        قیمت متری / اجاره
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-text-field
                            v-model="filters.MinmumPricePerMeter"
                            dense
                            outlined
                            label="از قیمت"
                        />

                        <v-text-field
                            v-model="filters.MaximumPricePerMeter"
                            dense
                            outlined
                            label="تا قیمت"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col :cols="12">
                  <v-row dense>
                    <v-col cols="6">
                      <v-combobox
                          :items="locations"
                          item-value="id"
                          item-text="name"
                          multiple
                          outlined
                          hide-details
                          chips
                          small-chips
                          deletable-chips
                          label="انتخاب مناطق"
                          v-model="filters.locationsid"
                      />
                    </v-col>
                    <v-col :cols="6">
                      <v-text-field
                          height="55px"
                          v-model="filters.PropertyAddress"
                          class="w-100"
                          outlined
                          hide-details
                          dense
                          label="آدرس ملک"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <FileLoading v-if="files.loading && files.items.length === 0"/>

          <FileList
              :key="files.items.length"
              :items="files.items"
              v-if="!files.loading && files.items.length !== 0">
            <tr
                class=""
                v-for="(item,index) in files.items"
                :key="index"
            >
              <td class="text-center pa-0">{{ index + 1 }}</td>
              <td class="text-center pa-0 d-flex align-center justify-start" style="font-size: 13px;line-height: 30px">
                <div class="py-2">
                  {{ item.title }}
                  <br>
                  {{ item.propertyAddress }}
                </div>
              </td>
              <td class="text-center pa-0">
                {{ item.ownerName }} 
                <br/>
                {{ item.phone1 }} 
              </td>
           
              <td class="text-center pa-0">{{ item.totalPrice }}</td>
              <td class="text-center pa-0">{{ item.pricePerMeter }}</td>
              <td class="text-center pa-0">{{ item.floorNumber }}</td>
              <td class="text-center pa-0">{{ item.dateSelection }}</td>
              <td class="text-center pa-0" style="width: 130px;">
                <v-btn
                    @click="item.actions.view"
                    color="main"
                    x-small
                    icon
                    text
                    fab>
                  <v-icon small>
                    mdi-file-document
                  </v-icon>
                </v-btn>
                <v-btn
                    @click="item.actions.edit"
                    color="main"
                    small
                    icon
                    text
                    fab>
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                    @click="item.actions.delete"
                    color="error"
                    small
                    icon
                    text
                    fab>
                  <v-icon small>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </FileList>

          <EmptyResult v-if="!files.loading && files.items.length === 0"/>

          <div
              v-if="!files.loading && files.items.length !== 0"
              class="d-flex align-center justify-center my-4">
            <Pagination
                v-model="filters.skip"
                :key="filters.skip"
                :totalItems="files.totalCount"
                :perPage="filters.take"
                @change="(page)=>{doSearch(false,{skip: page})}"
            />
          </div>

        </v-col>

        <v-col :cols="3">
          <div class="sticky-sidebar">
            <v-expansion-panels
                focusable
                hover
                popout
                active-class="active-panel-search"

            >
              <v-expansion-panel class="mb-2 ">
                <v-expansion-panel-header>
                  <div class="font-weight-bold text-body-2">
                    ویژگی های اصلی
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list
                      flat
                      dense
                      rounded
                      max-height="250"
                      style="overflow-y: auto"
                  >
                    <v-list-item
                        dense
                        v-for="(property,index) in fileProperties1"
                        :key="index"
                        @click="property.selected = !property.selected"
                    >

                      <v-list-item-action class="ml-2 ma-0">
                        <v-checkbox
                            :value="property.selected"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title class="">
                          {{ property.name }}
                        </v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header>
                  <div class="font-weight-bold text-body-2">
                    جزییات بیشتر
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list
                      flat
                      dense
                      rounded
                      max-height="250"
                      style="overflow-y: auto"
                  >
                    <v-list-item
                        dense
                        v-for="(property,index) in fileProperties2"
                        :key="index"
                        @click="property.selected = !property.selected"
                    >

                      <v-list-item-action class="ml-2 ma-0">
                        <v-checkbox
                            :value="property.selected"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title class="">
                          {{ property.name }}
                        </v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
                <v-divider
                    class="w-100"/>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header>
                  <div class="font-weight-bold text-body-2">
                    سیستم تهویه
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list
                      flat
                      dense
                      rounded
                      max-height="250"
                      style="overflow-y: auto"
                  >
                    <v-list-item
                        dense
                        v-for="(property,index) in fileProperties3"
                        :key="index"
                        @click="property.selected = !property.selected"
                    >

                      <v-list-item-action class="ml-2 ma-0">
                        <v-checkbox
                            :value="property.selected"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title class="">
                          {{ property.name }}
                        </v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
                <v-divider
                    class="w-100"/>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header>
                  <div class="font-weight-bold text-body-2">
                    آگهی دهنده
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list
                      flat
                      dense
                      rounded
                      max-height="250"
                      style="overflow-y: auto"
                  >
                    <v-list-item
                        dense
                        @click="filters.EstateFile = !filters.EstateFile"
                    >

                      <v-list-item-action class="ml-2 ma-0">
                        <v-checkbox
                            :value="filters.EstateFile"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title class="">
                          آگهی های املاک
                        </v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>
                    <v-list-item
                        dense
                        @click="filters.Ownerad = !filters.Ownerad"
                    >

                      <v-list-item-action class="ml-2 ma-0">
                        <v-checkbox
                            :value="filters.Ownerad"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title class="">
                          آگهی های شخصی
                        </v-list-item-title>
                      </v-list-item-content>

                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
                <v-divider
                    class="w-100"/>
              </v-expansion-panel>

              <v-expansion-panel class="mb-2">
                <v-expansion-panel-header>
                  <div class="font-weight-bold text-body-2">
                    سایر فیلتر ها
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="w-100 d-flex align-center justify-start flex-column py-2">
                    <div class="font-weight-bold text-body-2 d-flex align-center justify-start w-100">
                      تعداد طبقات
                    </div>
                    <div class="d-flex align-center justify-space-between ">
                      <v-text-field
                          v-model="filters.Floornumber1"
                          hide-details
                          type="number"
                          class="flex-grow-1 text-center"
                          outlined
                          dense
                          placeholder="از"
                      />
                      <div class="font-weight-bold text-body-2 flex-grow-0 mx-3">
                        تا
                      </div>
                      <v-text-field
                          v-model="filters.Floornumber2"
                          hide-details
                          class="flex-grow-1 text-center"
                          outlined
                          dense
                          type="number"
                          placeholder="تا"
                      />
                    </div>
                  </div>
                  <v-divider/>

                  <div class="w-100 d-flex align-center justify-start flex-column py-2">
                    <div class="font-weight-bold text-body-2 d-flex align-center justify-start w-100">
                      سن بنا
                    </div>
                    <div class="d-flex align-center justify-space-between ">
                      <v-text-field
                          v-model="filters.Buildingage1"
                          hide-details
                          type="number"
                          class="flex-grow-1 text-center"
                          outlined
                          dense
                          placeholder="از"
                      />
                      <div class="font-weight-bold text-body-2 flex-grow-0 mx-3">
                        تا
                      </div>
                      <v-text-field
                          v-model="filters.Buildingage2"
                          hide-details
                          class="flex-grow-1 text-center"
                          outlined
                          dense
                          type="number"
                          placeholder="تا"
                      />
                    </div>
                  </div>
                  <v-divider/>

                  <v-row dense>

                    <v-col :cols="6">
                      <div class="w-100 d-flex align-center justify-start flex-column py-2">
                        <v-text-field
                            v-model="filters.OwnerName"
                            class="w-100"
                            outlined
                            hide-details
                            dense
                            label="نام مالک"
                        />
                      </div>
                    </v-col>

                    <v-col :cols="6">
                      <div class="w-100 d-flex align-center justify-start flex-column py-2">
                        <v-text-field
                            class="w-100"
                            outlined
                            hide-details
                            dense
                            label="شماره فایل"
                            v-model="filters.Fileid"
                        />
                      </div>
                    </v-col>

                    <v-col :cols="12">
                      <div class="w-100 d-flex align-center justify-start flex-column py-2">
                        <v-text-field
                            class="w-100"
                            outlined
                            hide-details
                            dense
                            label="شماره موبایل"
                            v-model="filters.Mobile"
                        />
                      </div>
                    </v-col>

                    <v-col :cols="12">
                      <div class="w-100 d-flex align-center justify-start flex-column py-2">
                        <v-select
                            class="w-100"
                            outlined
                            hide-details
                            dense
                            :items="admins"
                            item-text="name"
                            item-value="id"
                            label="نویسنده"
                            v-model="filters.senderuserid"
                        />
                      </div>
                    </v-col>
                  </v-row>

                </v-expansion-panel-content>
                <v-divider
                    class="w-100"/>
              </v-expansion-panel>

              <div class="px-4 d-flex w-100 mt-4">
                <div class="flex-grow-1">
                  <v-btn
                      @click="doSearch(false,{skip:0,take:10})"
                      block
                      large
                      color="main">
                    <div class="white--text">
                      جستجو
                    </div>
                  </v-btn>
                </div>
                <div class="flex-grow-0">
                  <v-btn
                      @click="doSearch(true)"
                      class="mr-2 text-body-1"
                      color="main"
                      large
                      outlined
                  >
                    <v-icon>
                      mdi-refresh
                    </v-icon>
                    ریست

                  </v-btn>
                </div>
              </div>


            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="deleteItem.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این فایل مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal=false"
              large text color="main">
            لغو
          </v-btn>


          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large color="error">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>


    <FileDetailsModal
        @updateList="()=>{
          file.modal = false;
          doSearch();
        }"
        v-model="file.modal"
        :file="file"
    />


  </v-card>
</template>

<script>

import FileDetailsModal from "@/components/FileDetailsModal"
import {AppConfig} from "@Newfiling/Services";
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import moment from 'jalali-moment';
import FileLoading from "@Newfiling/module-file/src/components/FileLoading";
import FileList from "@Newfiling/module-file/src/components/FileListRow";
import EmptyResult from "@Newfiling/module-file/src/components/EmptyList";
import {deleteFile, searchFile, updateFileStatus} from "@Newfiling/module-file/src/api";
import {addCommas} from "@persian-tools/persian-tools";
import Vue from "vue";
import ImageViewer from "@/components/ImageViewer"
import Pagination from "@/components/Pagination"
import {fetchItems as fetchAdminApi} from "@Newfiling/module-admin/src/api"

export default {
  name: 'DesktopFileSearch',

  data() {
    return {
      file: {
        modal: false,
      },
      allProperties: [],
      fileTypes: [],
      transactionType: [],
      locations: [],
      fileProperties1: [],
      fileProperties2: [],
      fileProperties3: [],
      sleepRoomItems: [],
      kitchenTypes: [],
      telephoneItems: [],
      wcItems: [],
      floorCoverItems: [],
      viewItems: [],
      documentItems: [],
      properties: [],
      floorItems: [],
      filters: {
        DateSelection1: null,
        DateSelection2: moment().locale('fa').format('jYYYY/jMM/jDD'),
        PropertyType: null,
        TypeOfTransaction: null,
        MinmumPricePerMeter: '',
        MaximumPricePerMeter: '',
        MinmumTotalprice: '',
        MaximumTotalprice: '',
        OwnerName: null,
        MinmumFoundation: null,
        MaximumFoundation: null,
        Floornumber1: null,
        Floornumber2: null,
        Foundation: null,
        KitchenType: null,
        Buildingage1: null,
        Buildingage2: null,
        PropertyAddress: null,
        EstateFile: false,
        Ownerad: false,
        Mobile: null,
        Fileid: null,
        SortSortparameter: 0,
        skip: 0,
        take: 10,
        Attributes: null,
        locationsid: null,
        senderuserid: null,
      },
      files: {
        items: [],
        loading: true,
        showMoreLoading: false,
        totalCount: 0,
      },
      sortItems: [
        {
          id: 0,
          name: 'جدید ترین ها'
        },
        {
          id: 2,
          name: 'بیشترین قیمت کل'
        },
        {
          id: 3,
          name: 'بیشترین متراژ'
        },
        {
          id: 5,
          name: 'بیشترین سن بنا'
        },
        {
          id: 6,
          name: 'بیشترین تعداد خواب'
        },
      ],
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },
      status: [
        {
          id: 0,
          name: 'تایید شده',
          loading: false,
          color: "success",
        },
        {
          id: 1,
          name: 'اجاره رفته',
          loading: false,
          color: "warning",
        },
        {
          id: 2,
          name: 'به فروش رفته',
          loading: false,
          color: "warning",
        },
        {
          id: 3,
          name: 'قیمت تغییر کرده',
          loading: false,
          color: "primary",
        },
        {
          id: 4,
          name: 'رد فایل',
          loading: false,
          color: "error",
        },

      ],
      admins: [],
    }
  },

  components: {
    FileLoading,
    ImageViewer,
    FileList,
    Pagination,
    EmptyResult,
    datePicker: VuePersianDatetimePicker,
    FileDetailsModal
  },

  mounted() {
    this.$nextTick(async () => {
      await this.fetchAdmins();
      const appConfigs = (await AppConfig) || {};
      this.floorNumberItems = appConfigs.Floornumber.map(item => {
        return {
          id: item.Id,
          name: item.FloorName,
        }
      })
      this.sleepRoomItems = appConfigs.Numberofsleeps.map(item => {
        return {
          id: item.Id,
          name: item.Numberofsleeps,
        }
      })
      this.transactionType = appConfigs.Typeoftransaction.map(item => {
        return {
          id: item.Id,
          name: item.Typeoftransaction,
        }
      })
      this.fileTypes = appConfigs.PropertyType.map(item => {
        return {
          id: item.Id,
          name: item.PropertyType,
        }
      })
      this.locations = appConfigs.neighborhoods.map(item => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        }
      })
      this.allProperties = appConfigs.Features.map(item => {
        return {
          type: item.Type,
          id: item.Id,
          name: item.Persianname,
          selected: false,
        }
      })
      this.fileProperties1 = this.allProperties.filter(item => item.type === 2)
      this.fileProperties2 = this.allProperties.filter(item => item.type === 1)
      this.fileProperties3 = this.allProperties.filter(item => item.type === 3)
      this.kitchenTypes = appConfigs.kitchen.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.floorItems = appConfigs.Floornumber.map(item => {
        return {
          id: item.Id,
          name: item.FloorName,
        }
      })
      this.sleepRoomItems = appConfigs.Numberofsleeps.map(item => {
        return {
          id: item.Id,
          name: item.Numberofsleeps,
        }
      })
      this.telephoneItems = appConfigs.Telephonelinenumber.map(item => {
        return {
          id: item.Id,
          name: item.Linestatus,
        }
      })
      this.wcItems = appConfigs.Wclist.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.floorCoverItems = appConfigs.FloorCovering.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.viewItems = appConfigs.ViewType.map(item => {
        return {
          id: item.Id,
          name: item.Servicename,
        }
      })
      this.documentItems = appConfigs.typeofDocument.map(item => {
        return {
          id: item.Id,
          name: item.Documentname,
        }
      })
      this.properties = appConfigs.Features.map(item => {
        return {
          id: item.Id,
          name: item.Persianname,
        }
      })
      await this.doSearch(true);
    })
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'جستجو آگهی ها',
        disabled: true,
        href: '#',
      },
    ])
  },

  methods: {

    async fetchAdmins() {
      const res = (await fetchAdminApi({
        Nameandfamily: null,
        accountType: 0,
        mobile: "",
        modal: false,
        skip: 0,
        take: 9999,
      }))?.data || [];
      this.admins = res.message.map(item => {
        return {
          name: item.nameandfamily,
          id: item.id
        }
      })
      console.log({res})
    },

    async doSearch(resetFilters = false, filters = {},) {
      resetFilters && this.resetFilters();
      this.filters = {
        ...this.filters,
        ...filters,
      }
      this.files.items = []
      this.files.loading = true;
      try {
        const getFileTitle = (file) => {
          let title = '';
          title = this.transactionType.find(i => i.id === file.typeOfTransaction)?.name + " ";
          title = title + this.fileTypes.find(i => i.id === file.propertyType)?.name + " در "
          title = title + this.locations.find(i => i.id === file.locationsid)?.name
          return title;
        }
        const res = (await searchFile({
          ...this.filters,
          Attributes: this.getActiveAttributes(),
          locationsid: this.getSelectedLocations(),
          MinmumPricePerMeter: this.filters.MinmumPricePerMeter.replaceAll(',', ''),
          MaximumPricePerMeter: this.filters.MaximumPricePerMeter.replaceAll(',', ''),
          MinmumTotalprice: this.filters.MinmumTotalprice.replaceAll(',', ''),
          MaximumTotalprice: this.filters.MaximumTotalprice.replaceAll(',', ''),
          skip: this.filters.skip * this.filters.take,
        }))?.data || {
          filelist: [],
          recordcount: 0
        };
        this.files.totalCount = res.recordcount;
        this.files.items = res.filelist.map(item => {
          return {
            ...item,
            id: item.id,
            title: this.getFileTitle(item),
            totalPrice: addCommas(item.totalprice),
            pricePerMeter: addCommas(item.pricePerMeter),
            floorNumber: this.floorNumberItems.find(i => i.id === item.floorNumber)?.name,
            actions: {
              edit: () => {
                window.open('/#/panel/files/edit/' + item.id, 'blank')
              },
              view: () => {
                this.file = {
                  ...item,
                  id: item.id,
                  modal: true,
                  title: getFileTitle(item),
                  totalprice: addCommas(item.totalprice),
                  pricePerMeter: addCommas(item.pricePerMeter),
                  kitchenType: this.kitchenTypes.find(i => i.id === item.kitchenType)?.name,
                  numberOfSleeps: this.sleepRoomItems.find(i => i.id === item.numberOfSleeps)?.name,
                  floorNumber: this.floorItems.find(i => i.id === item.floorNumber)?.name,
                  telephoneType: this.telephoneItems.find(i => i.id === item.phoneStatus)?.name,
                  wctype: this.wcItems.find(i => i.id === item.wctype)?.name,
                  floorCoveringType: this.floorCoverItems.find(i => i.id === item.floorCoveringType)?.name,
                  viewType: this.viewItems.find(i => i.id === item.viewtype)?.name,
                  typeofDocument: this.documentItems.find(i => i.id === item.typeofDocument)?.name,
                }
              },
              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },
            }
          }
        });
      } catch (e) {
        console.log(e)
      }
      this.files.loading = false;
    },

    resetFilters() {
      this.filters = {
        ...this.filters,
        DateSelection1: null,
        DateSelection2: moment().locale('fa').format('jYYYY/jMM/jDD'),
        PropertyType: null,
        TypeOfTransaction: null,
        MinmumPricePerMeter: "",
        MaximumPricePerMeter: "",
        MinmumTotalprice: "",
        MaximumTotalprice: "",
        OwnerName: null,
        MinmumFoundation: null,
        MaximumFoundation: null,
        Floornumber1: null,
        Floornumber2: null,
        Foundation: null,
        KitchenType: null,
        Buildingage1: null,
        Buildingage2: null,
        PropertyAddress: null,
        EstateFile: false,
        Ownerad: false,
        Mobile: null,
        Fileid: null,
        SortSortparameter: 0,
        skip: 0,
        take: 10,
        Attributes: null,
        locationsid: null,
      }
      this.fileProperties1 = this.fileProperties1.map(item => {
        return {
          ...item,
          selected: false,
        }
      })
      this.fileProperties2 = this.fileProperties2.map(item => {
        return {
          ...item,
          selected: false,
        }
      })
      this.fileProperties3 = this.fileProperties3.map(item => {
        return {
          ...item,
          selected: false,
        }
      })
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await deleteFile(this.deleteItem.id);
        this.$toast.success('فایل مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.doSearch();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },

    getFileTitle(file) {
      const fileTransaction = this.transactionType.find(item => item.id === file.typeOfTransaction)?.name ?? '';
      const fileType = this.fileTypes.find(item => item.id === file.propertyType)?.name ?? '';
      const fileLocation = this.locations.find(item => item.id === file.locationsid)?.name ?? '';
      const baseArea = file.foundation ? file.foundation + ' متری ' : null;
      return `${fileTransaction} ${fileType} ${baseArea}  در  ${fileLocation}`;
    },

    getActiveAttributes() {
      const attributes = [
        ...this.fileProperties1,
        ...this.fileProperties2,
        ...this.fileProperties3,
      ];
      return attributes
          .filter(i => i.selected)
          .map(i => i.id)
          .join('-') || null
    },

    normalizeFileAttributes(file) {
      const fileAttributes = file?.attributes || '';
      return fileAttributes?.split('-').map(item => parseInt(item)) || [];
    },

    getSelectedLocations() {
      return this.filters.locationsid
          ? this.filters.locationsid.map(i => i.id).join('-') || null
          : null;
    },

    async updateFileStatus(status) {
      if (status.loading) return;
      status.loading = true;
      try {
        await updateFileStatus({
          Id: this.file.id,
          Filestatus: status.id
        });
        this.file.status = status.id;
        this.files.items[this.files.items.findIndex(i => i.id === this.file.id)] = {
          ...this.files.items[this.files.items.findIndex(i => i.id === this.file.id)],
          status
        }
        const text = `وضعیت فایل به ${status.name}  تغییر کرد. `
        this.$toast.success(text)
      } catch (e) {
        console.log(e)
      }
      status.loading = false;

    }

  },

  watch: {
    'filters.MinmumPricePerMeter'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.filters.MinmumPricePerMeter = result);
    },
    'filters.MaximumPricePerMeter'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.filters.MaximumPricePerMeter = result);
    },
    'filters.MinmumTotalprice'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.filters.MinmumTotalprice = result);
    },
    'filters.MaximumTotalprice'(newValue) {
      const result = newValue.replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => this.filters.MaximumTotalprice = result);
    },
  }

}
</script>

<style scoped>
td {
  height: auto !important;
}

.active-panel-search {
  border: 2px solid var(--v-main-base)
}

.active-sort {
  color: var(--v-main-base) !important;
}


</style>
